import {
  GET_ROUNDING_LIST_RES,
  GET_ROUNDING_CACHE_RES,
  ADD_ROUNDING_RES,
  GET_ROUNDING_DETAILS_RES,
  SAVE_ROUNDING_DETAILS_RES,
  EDIT_ROUNDING_RES,
  SEARCH_PATIENT_RES,
  SAVE_PATIENT_RES,
  DELETE_PATIENT_RES,
  DISCHARGE_PATIENT_RES,
} from "./rounding.types";

const INITIAL_STATE = {
  roundingListRes: [],
  roundingCacheRes: {},
  addRoundingRes: {},
  roundingDetailsRes: {},
  saveRoundingDetailsRes: {},
  editRoundingRes: {},
  searchPatientResponse: [],
  savePatientResponse: {},
  deletePatientRes: {},
  dischargePatientRes: {},
};

const roundingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ROUNDING_LIST_RES: {
      return { ...state, ...{ roundingListRes: action.payload } };
    }
    case GET_ROUNDING_CACHE_RES: {
      return { ...state, ...{ roundingCacheRes: action.payload } };
    }
    case ADD_ROUNDING_RES: {
      return { ...state, ...{ addRoundingRes: action.payload } };
    }
    case GET_ROUNDING_DETAILS_RES: {
      return { ...state, ...{ roundingDetailsRes: action.payload } };
    }
    case SAVE_ROUNDING_DETAILS_RES: {
      return { ...state, ...{ saveRoundingDetailsRes: action.payload } };
    }
    case EDIT_ROUNDING_RES: {
      return { ...state, ...{ editRoundingRes: action.payload } };
    }
    case SEARCH_PATIENT_RES: {
      return { ...state, ...{ searchPatientResponse: action.payload } };
    }
    case SAVE_PATIENT_RES: {
      return { ...state, ...{ savePatientResponse: action.payload } };
    }
    case DELETE_PATIENT_RES: {
      return { ...state, ...{ deletePatientRes: action.payload } };
    }
    case DISCHARGE_PATIENT_RES: {
      return { ...state, ...{ dischargePatientRes: action.payload } };
    }
    default:
      return state;
  }
};

export default roundingReducer;
