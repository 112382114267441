/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Merina Joy <merina@tensaw.email>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */

export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const GET_ROUNDING_LIST = "GET_ROUNDING_LIST";
export const GET_ROUNDING_LIST_RES = "GET_ROUNDING_LIST_RES";
export const GET_ROUNDING_CACHE = "GET_ROUNDING_CACHE";
export const GET_ROUNDING_CACHE_RES = "GET_ROUNDING_CACHE_RES";
export const ADD_ROUNDING = "ADD_ROUNDING";
export const ADD_ROUNDING_RES = "ADD_ROUNDING_RES";
export const GET_ROUNDING_DETAILS = "GET_ROUNDING_DETAILS";
export const GET_ROUNDING_DETAILS_RES = "GET_ROUNDING_DETAILS_RES";
export const SAVE_ROUNDING_DETAILS = "SAVE_ROUNDING_DETAILS";
export const SAVE_ROUNDING_DETAILS_RES = "SAVE_ROUNDING_DETAILS_RES";
export const EDIT_ROUNDING = "EDIT_ROUNDING";
export const EDIT_ROUNDING_RES = "EDIT_ROUNDING_RES";
export const SEARCH_PATIENT = "SEARCH_PATIENT";
export const SEARCH_PATIENT_RES = "SEARCH_PATIENT_RES";
export const SAVE_PATIENT = "SAVE_PATIENT";
export const SAVE_PATIENT_RES = "SAVE_PATIENT_RES";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const DELETE_PATIENT_RES = "DELETE_PATIENT_RES";
export const DISCHARGE_PATIENT = "DISCHARGE_PATIENT";
export const DISCHARGE_PATIENT_RES = "DISCHARGE_PATIENT_RES";
