import { combineEpics } from "redux-observable";
import LoginEpic from "./Login/login.epic";
import ImagesEpic from "./Images/images.epic";
import RoundingEpic from "./Rounding/rounding.epic";

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Merina Joy <merina@tensaw.email>
 */

const rootEpic = combineEpics(
  LoginEpic.login,
  LoginEpic.logout,
  ImagesEpic.getImageList,
  ImagesEpic.downloadFile,
  ImagesEpic.updateFile,
  RoundingEpic.getRoundingList,
  RoundingEpic.getRoundingCache,
  RoundingEpic.addRounding,
  RoundingEpic.editRounding,
  RoundingEpic.getRoundingDetails,
  RoundingEpic.saveRoundingDetails,
  RoundingEpic.searchPatient,
  RoundingEpic.savePatient,
  RoundingEpic.deletePatient,
  RoundingEpic.dischargePatient
);

export default rootEpic;
