import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dropdown from "components/Dropdown";
import dischargeIcon from "../../assets/icons/discharged_flag_icon.svg";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import StarIcon from "@material-ui/icons/Star";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Datepicker from "components/DatePicker";
import {
  getRoundingList,
  addRounding,
  editRounding,
  getRoundingDetails,
  saveRoundingDetails,
  searchPatient,
  savePatient,
  deletePatient,
  dischargePatient,
} from "Redux/Rounding/rounding.actions";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  Grid,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import "./style.css";
import toast from "react-hot-toast";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const dateFormat = (val) => {
  let date = new Date(val);
  let dt = date.getDate();
  let day;
  if (dt < 10) {
    day = "0" + dt;
  } else {
    day = dt;
  }
  let month = date.getMonth() + 1;
  let m;
  if (month < 10) {
    m = "0" + month;
  } else {
    m = month;
  }
  let year = date.getFullYear();
  return year + "-" + m + "-" + day + " " + "00:00:00";
};

function Rounding(props) {
  const [rows, setRows] = useState([]);
  let providerId = parseInt(localStorage.getItem("providerId"));
  let clinicId = parseInt(localStorage.getItem("clinicId"));
  let userId = parseInt(localStorage.getItem("userId"));
  const [selectedRounding, setSelectedRounding] = useState("");
  const [locations, setLocations] = useState([]);
  const [providers, setProviders] = useState([]);
  const [status, setStatus] = useState(1);
  const [ip, setIp] = useState(true);
  const [addPatient, setAddPatient] = useState(false);
  const [cptTab, setCptTab] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCharges, setOpenCharges] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openDischarge, setOpenDischarge] = useState(false);
  const [openAddRounding, setOpenAddRounding] = useState(false);
  const [dischargeDetails, setDischargeDetails] = useState({
    userId: userId,
    roundingId: "",
    dispositionLocation: "Home",
    dispositionType: "1",
  });
  const [openBillingNotes, setOpenBillingNotes] = useState(false);
  const [billerNote, setBillerNote] = useState("");
  const [roundingNote, setRoundingNote] = useState("");
  const [details, setDetails] = useState({});
  const [visitDetails, setVisitDetails] = useState({});
  const [patientDto, setPatientDto] = useState({});
  const [cptList, setCptList] = useState([]);
  const [icdList, setIcdList] = useState({
    dx1: { icdCode: "" },
    dx2: { icdCode: "" },
    dx3: { icdCode: "" },
    dx4: { icdCode: "" },
  });
  const [showTable, setShowTable] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [patientName, setPatientName] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientInfo, setPatientInfo] = useState({
    active: 0,
    addressLine1: "",
    addressLine2: "",
    altPhone: "",
    balanceWriteoff: 0,
    billingMethod: 0,
    city: "",
    clinicId: 0,
    clinicName: "",
    coins: "",
    copay: "",
    country: "",
    deductable: 0,
    dob: null,
    dos: "",
    email: "",
    emergencyContact: "",
    emergencyPhone: "",
    firstName: "",
    id: 0,
    insuranceBalance: 0,
    lastName: "",
    locationId: 0,
    locationName: "",
    maritalStatus: 0,
    masterPatientId: 0,
    middleName: "",
    mrn: "",
    notes: "",
    patientBalance: 0,
    patientOptout: 0,
    payorName: "",
    phone: "",
    pinNo: "",
    policyType: 0,
    providerId: 0,
    providerName: "",
    sdob: "",
    sex: 0,
    ssn: "",
    state: "",
    userId: 0,
    userName: "",
    zip: "",
  });
  const [roundingValues, setRoundingValues] = useState({
    bed: "",
    providerId: "",
    notes: "",
    roundingId: 0,
    locationId: "",
    isMedicare: true,
    clinicId: clinicId,
    startDate: null,
    patientId: 0,
  });

  const [editRoundingValues, setEditRoundingValues] = useState({
    bed: "",
    providerId: "",
    refProviderId: "",
    refProviderName: "",
    notes: "",
    roundingId: 0,
    locationId: "",
    isMedicare: true,
    clinicId: clinicId,
    startDate: null,
    patientId: 0,
  });

  const [headCells, setHeadCells] = useState([
    {
      id: "mrn",
      label: "MRN",
    },
    {
      id: "patient",
      label: "Patient",
    },
    {
      id: "dob",
      label: "DOB",
    },
    {
      id: "facility",
      label: "Facility",
    },
    {
      id: "bed",
      label: "Bed",
    },
    {
      id: "los",
      label: "LOS",
    },
    {
      id: "flag",
      label: "Flag",
    },
    {
      id: "dos",
      label: "DOS",
    },
  ]);

  const formatDate = (val) => {
    let d = val.split("-");
    return `${d[1]}/${d[2]}/${d[0]}`;
  };

  const handleAddIcon = () => {
    setOpenAddRounding(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleCptTab = (event, newValue) => {
    setCptTab(newValue);
  };

  useEffect(() => {
    props.getRoundingList(providerId);
    let cache = JSON.parse(localStorage.getItem("roundingCache"));
    console.log(cache);
    setProviders(
      cache.provider.map((x) => {
        return { name: x.fullName, value: x.id };
      })
    );
    setLocations(
      cache.locations.map((x) => {
        return { name: x.locationName, value: x.id };
      })
    );
  }, []);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.roundingListRes &&
      props.roundingData.roundingListRes.response
    ) {
      if (props.roundingData.roundingListRes.response.responseCode === 0) {
        let data = props.roundingData.roundingListRes.response.data;
        setRows(data);
        props.getRoundingDetails(data[0].roundingId);
      } else {
        toast.error("Unexpected error. Try again later.");
      }
    }
  }, [props.roundingData.roundingListRes]);

  const handleAddRounding = () => {
    props.addRounding({
      ...roundingValues,
      startDate: dateFormat(roundingValues.startDate),
    });
  };

  const handleEditRounding = () => {
    props.editRounding({
      ...editRoundingValues,
      startDate: dateFormat(editRoundingValues.startDate),
    });
  };

  const fillData = (x) => {
    let p = patientList.filter((item) => item.id === x.id)[0];
    setPatientName(p.firstName + " " + p.lastName);
    setRoundingValues({
      ...roundingValues,
      patientId: p.id,
    });
  };

  const handleSearchPatient = () => {
    props.searchPatient({
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      dob: patientInfo.dob ? dateFormat(patientInfo.dob) : "",
      clinicId: clinicId,
    });
  };

  const handleNewPatient = () => {
    setAddPatient(true);
  };

  const handleSavePatient = () => {
    props.savePatient(patientInfo);
  };

  const handleSaveRoundingDetails = () => {
    props.saveRoundingDetails({
      providerId: details.providerId,
      visitId: visitDetails.visitId,
      visitDiagnosiDto: {
        visitDiagnosisId: icdList.visitDiagnosisId,
        dx1: {
          clinicId: icdList.dx1.clinicId,
          icdDescShort: icdList.dx1.icdDescShort,
          icdId: icdList.dx1.icdId,
          favourite: icdList.dx1.favourite,
          icdDescLong: icdList.dx1.icdDescLong,
          icdCode: icdList.dx1.icdCode,
          rangeId: icdList.dx1.rangeId,
        },
        dx2: {
          clinicId: icdList.dx2.clinicId,
          icdDescShort: icdList.dx2.icdDescShort,
          icdId: icdList.dx2.icdId,
          favourite: icdList.dx2.favourite,
          icdDescLong: icdList.dx2.icdDescLong,
          icdCode: icdList.dx2.icdCode,
          rangeId: icdList.dx2.rangeId,
        },
      },
      dos: visitDetails.dos,
      roundingId: details.roundingId,
      clinicId: visitDetails.clinicId,
      appointmentId: 0,
      status: 1,
      locationId: details.locationId,
      inOutPatientStatus: 1,
      providerName: details.providerName,
      patientId: details.patientId,
      id: 0,
      isDelete: false,
      visitServiceDtoList: cptList,
      note: "",
    });
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.searchPatientResponse &&
      props.roundingData.searchPatientResponse.response
    ) {
      if (
        props.roundingData.searchPatientResponse.response.responseCode === 0
      ) {
        let data = props.roundingData.searchPatientResponse.response.data;
        if (data.length) {
          setShowTable(true);
          setPatientList(data);
        }
      }
    }
  }, [props.roundingData.searchPatientResponse.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.savePatientResponse &&
      props.roundingData.savePatientResponse.response
    ) {
      if (props.roundingData.savePatientResponse.response.responseCode === 0) {
        toast.success("Patient added successfully");
        setAddPatient(false);
        let data = props.roundingData.savePatientResponse.response.data;
        if (data.id) {
          let id = data.id;
          setRoundingValues({
            ...roundingValues,
            patientId: id,
          });
          setPatientName(patientInfo.firstName + " " + patientInfo.lastName);
          // let p = patientList.filter((item) => item.id === id)[0];
          // setPatientName(p.firstName + " " + p.lastName);
        }
      }
    }
  }, [props.roundingData.savePatientResponse.response]);

  const handleRowSelect = (id) => {
    props.getRoundingDetails(id);
    setSelectedRounding(id);
  };

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.roundingDetailsRes &&
      props.roundingData.roundingDetailsRes.response
    ) {
      if (props.roundingData.roundingDetailsRes.response.responseCode === 0) {
        let data = props.roundingData.roundingDetailsRes.response.data;
        setDetails(data.roundingDetails);
        setSelectedRounding(data.roundingDetails.roundingId);
        setVisitDetails(data.visitChargeDto);
        setPatientDto(
          data.visitChargeDto ? data.visitChargeDto.patientDto : {}
        );
        setIcdList(
          data.visitChargeDto
            ? data.visitChargeDto.visitDiagnosiDto
            : {
                dx1: { icdCode: "" },
                dx2: { icdCode: "" },
                dx3: { icdCode: "" },
                dx4: { icdCode: "" },
              }
        );
        setCptList(
          data.visitChargeDto ? data.visitChargeDto.visitServiceDtoList : []
        );
        let r = data.roundingDetails;
        setEditRoundingValues({
          ...editRoundingValues,
          bed: r.patientBed,
          providerId: r.providerId,
          refProviderId: r.refProviderId,
          refProviderName: "",
          notes: r.notes,
          roundingId: r.roundingId,
          locationId: r.locationId,
          isMedicare: r.isMedicare,
          startDate: new Date(r.startDate),
          patientId: r.patientId,
        });
        setSelectedPatient(r.patientFirstName + " " + r.patientLastName);
      } else {
        toast.error("Unexpected error. Try again later.");
      }
    }
  }, [props.roundingData.roundingDetailsRes]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.addRoundingRes &&
      props.roundingData.addRoundingRes.response
    ) {
      if (props.roundingData.addRoundingRes.response.responseCode === 0) {
        toast.success("New rounding added");
        props.getRoundingList(providerId);
        setOpenAddRounding(false);
      } else if (
        props.roundingData.addRoundingRes.response.responseCode === 132
      ) {
        toast.error("Patient already exists in rounding");
        setOpenAddRounding(false);
      } else {
        toast.error("Unable to add rounding");
        setOpenAddRounding(false);
      }
    }
  }, [props.roundingData.addRoundingRes.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.editRoundingRes &&
      props.roundingData.editRoundingRes.response
    ) {
      if (props.roundingData.editRoundingRes.response.responseCode === 0) {
        toast.success("Rounding updated successfully");
        props.getRoundingList(providerId);
        setOpenEdit(false);
      } else {
        toast.error("Unable to update rounding");
        setOpenEdit(false);
      }
    }
  }, [props.roundingData.editRoundingRes.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.deletePatientRes &&
      props.roundingData.deletePatientRes.response
    ) {
      if (props.roundingData.deletePatientRes.response.responseCode === 0) {
        toast.success("Patient deleted successfully");
        props.getRoundingList(providerId);
        setOpenRemove(false);
      } else {
        toast.error("Unable to delete patient");
        setOpenRemove(false);
      }
    }
  }, [props.roundingData.deletePatientRes.response]);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.dischargePatientRes &&
      props.roundingData.dischargePatientRes.response
    ) {
      if (props.roundingData.dischargePatientRes.response.responseCode === 0) {
        toast.success("Patient discharged successfully");
        props.getRoundingList(providerId);
        setOpenDischarge(false);
      } else {
        toast.error("Unable to discharge patient");
        setOpenDischarge(false);
      }
    }
  }, [props.roundingData.dischargePatientRes.response]);

  return (
    <div className="rounding_main_container">
      <Dialog open={openCharges} keepMounted fullWidth maxWidth="lg">
        <div className="new_rounding_modal_container">
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "1rem",
              paddingLeft: "15px",
            }}
          >
            Past Charges
          </div>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>DOS</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>CPT</TableCell>
                  <TableCell>ICD1</TableCell>
                  <TableCell>ICD2</TableCell>
                  <TableCell>ICD3</TableCell>
                  <TableCell>ICD4</TableCell>
                  <TableCell>M1</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>08/23/2023</TableCell>
                  <TableCell>Vinit Lal</TableCell>
                  <TableCell>99232</TableCell>
                  <TableCell>E23.1</TableCell>
                  <TableCell>E11.9</TableCell>
                  <TableCell>E11.36</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    style={{ textDecoration: "underline", color: "#14a6a6" }}
                  >
                    Copy
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>08/23/2023</TableCell>
                  <TableCell>Vinit Lal</TableCell>
                  <TableCell>99232</TableCell>
                  <TableCell>E23.1</TableCell>
                  <TableCell>E11.9</TableCell>
                  <TableCell>E11.36</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    style={{ textDecoration: "underline", color: "#14a6a6" }}
                  >
                    Copy
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>08/23/2023</TableCell>
                  <TableCell>Vinit Lal</TableCell>
                  <TableCell>99232</TableCell>
                  <TableCell>E23.1</TableCell>
                  <TableCell>E11.9</TableCell>
                  <TableCell>E11.36</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    style={{ textDecoration: "underline", color: "#14a6a6" }}
                  >
                    Copy
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </Dialog>

      <Dialog open={openEdit}>
        <div className="new_rounding_modal_container" fullWidth maxWidth="lg">
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "1.5rem",
            }}
          >
            Edit Rounding
          </div>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                value={selectedPatient}
                variant="outlined"
                label="Patient"
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={editRoundingValues.locationId}
                options={locations}
                variant="outlined"
                label="Location"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    locationId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={editRoundingValues.providerId}
                options={providers}
                variant="outlined"
                label="Physician"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    providerId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={editRoundingValues.refProviderId}
                options={providers}
                variant="outlined"
                label="Referring Physician"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    refProviderId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                className="rounding_input_item"
                value={editRoundingValues.bed}
                variant="outlined"
                label="Bed"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    bed: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Datepicker
                className="rounding_input_item_date"
                value={editRoundingValues.startDate}
                label="Admit Date"
                inputVariant="outlined"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    startDate: e,
                  })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                className="rounding_input_item"
                value={editRoundingValues.notes}
                variant="outlined"
                label="Notes"
                onChange={(e) =>
                  setEditRoundingValues({
                    ...editRoundingValues,
                    notes: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <div style={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  onClick={() => handleEditRounding()}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenEdit(false)}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <Dialog open={openNotes} fullWidth>
        <div className="new_rounding_modal_container">
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "1rem",
              paddingLeft: "15px",
            }}
          >
            Rounding Notes
          </div>
          <div className="notesTabForm_container">
            <div className="notesTab__input">
              <TextField
                type="text"
                label="Add note"
                // value={newNote}
                // onChange={getNewNote}
                variant="outlined"
              />
            </div>

            <div className="notesTab__btnContainer">
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                // onClick={() => setNewNote("")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                // onClick={handleSubmit}
                // disabled={isSaveClicked}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={openRemove} fullWidth>
        <div className="new_rounding_modal_container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
              fontWeight: "600",
              paddingBottom: "10px",
            }}
          >
            <center>
              Are you sure you want to delete this patient from your rounding
              list?
            </center>
          </div>

          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{ color: "white" }}
              onClick={() => props.deletePatient(details.roundingId)}
            >
              Delete
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpenRemove(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={openDischarge} fullWidth>
        <div className="new_rounding_modal_container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <center>
              Are you sure you want to discharge this patient from your rounding
              list?
            </center>
          </div>
          <div
            style={{
              display: "flex",
              gap: "12px",
              justifyContent: "center",
              fontSize: "17px",
            }}
          >
            <div style={{ paddingTop: "1.3rem" }}>
              Select disposition location:{" "}
            </div>
            <div>
              <Dropdown
                value={dischargeDetails.dispositionType}
                options={[
                  { name: "Home", value: "1" },
                  { name: "Rehab/SNF", value: "2" },
                  { name: "No f/u", value: "0" },
                ]}
                onChange={(e) =>
                  setDischargeDetails({
                    ...dischargeDetails,
                    roundingId: selectedRounding,
                    dispositionType: e.target.value,
                    dispositionLocation: e.target.name,
                  })
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{ color: "white" }}
              onClick={() =>
                props.dischargePatient({
                  ...dischargeDetails,
                  roundingId: selectedRounding,
                })
              }
            >
              Discharge
            </Button>
            <Button color="primary" variant="outlined">
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={openAddRounding} keepMounted fullWidth maxWidth="lg">
        <div className="new_rounding_modal_container">
          {addPatient ? (
            <div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  paddingBottom: "1.5rem",
                  // paddingLeft: "15px",
                }}
              >
                Add New Patient
              </div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="First Name"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        firstName: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Middle Name"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        middleName: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Last Name"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        lastName: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <Datepicker
                    className="rounding_input_item_date"
                    value={patientInfo.dob}
                    label="DOB"
                    inputVariant="outlined"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        dob: e,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Phone"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        phone: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Gender"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        sex: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Email"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="MRN"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        mrn: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Address Line 1"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        addressLine1: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Address Line 2"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        addressLine2: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Zip"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        zip: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="City"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        city: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="State"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        state: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    className="rounding_input_item"
                    variant="outlined"
                    label="Country"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        country: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <div
                    style={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        onClick={handleSavePatient}
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setAddPatient(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  paddingBottom: "1rem",
                  // paddingLeft: "15px",
                }}
              >
                Patient Rounding Information
              </div>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label="Last Name"
                    value={patientInfo.lastName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        lastName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label="First Name"
                    value={patientInfo.firstName}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        firstName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Datepicker
                    className="rounding_input_item_date"
                    value={patientInfo.dob}
                    label="DOB"
                    inputVariant="outlined"
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        dob: e,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <div
                    style={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        onClick={handleSearchPatient}
                      >
                        Search
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleNewPatient}
                      >
                        New
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}

          {showTable && (
            <Grid xs={12} className="patient_search_table">
              <TableContainer style={{ height: 250 }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="cell_head" align="left">
                        Last Name
                      </TableCell>
                      <TableCell className="cell_head" align="left">
                        First Name
                      </TableCell>
                      <TableCell className="cell_head" align="left">
                        MRN
                      </TableCell>
                      <TableCell className="cell_head" align="left">
                        DOB
                      </TableCell>
                      <TableCell className="cell_head" align="left">
                        Gender
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {patientList ? (
                    patientList.length !== 0 ? (
                      <TableBody>
                        {patientList.map((x) => (
                          <TableRow
                            style={{ cursor: "pointer" }}
                            onClick={() => fillData(x)}
                          >
                            <TableCell className="cell_info" align="left">
                              {x.lastName}
                            </TableCell>
                            <TableCell className="cell_info" align="left">
                              {x.firstName}
                            </TableCell>
                            <TableCell className="cell_info" align="left">
                              {x.mrn}
                            </TableCell>
                            <TableCell className="cell_info" align="left">
                              {x.sdob}
                            </TableCell>
                            <TableCell className="cell_info" align="left">
                              {x.sex === 1 ? "Male" : "Female"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan="5">
                            No data found
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan="5">
                          No data found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          )}

          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              padding: "2rem 0 1rem",
              // paddingLeft: "15px",
            }}
          >
            Add Rounding Information
          </div>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                className="rounding_input_item"
                value={patientName}
                variant="outlined"
                label="Patient"
                // onChange={(e) =>
                //   setRoundingValues({
                //     ...roundingValues,
                //     patientId: e.target.value,
                //   })
                // }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={roundingValues.locationId}
                options={locations}
                variant="outlined"
                label="Location"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    locationId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={roundingValues.providerId}
                options={providers}
                variant="outlined"
                label="Physician"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    providerId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                className="rounding_input_item"
                value={roundingValues.refProviderId}
                options={providers}
                variant="outlined"
                label="Referring Physician"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    refProviderId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                className="rounding_input_item"
                value={roundingValues.bed}
                variant="outlined"
                label="Bed"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    bed: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Datepicker
                className="rounding_input_item_date"
                value={roundingValues.startDate}
                label="Admit Date"
                inputVariant="outlined"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    startDate: e,
                  })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                className="rounding_input_item"
                value={roundingValues.notes}
                variant="outlined"
                label="Notes"
                onChange={(e) =>
                  setRoundingValues({
                    ...roundingValues,
                    notes: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <div style={{ display: "flex", gap: "1rem", padding: "1rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  onClick={handleAddRounding}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setRoundingValues({
                      bed: "",
                      providerId: "",
                      notes: "",
                      roundingId: "",
                      locationId: "",
                      isMedicare: true,
                      clinicId: clinicId,
                      startDate: null,
                      patientId: "",
                    })
                  }
                >
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      {/* <div className="rounding_filter_container">
        <div className="rounding_pagination_container">
          <TablePagination
            component="div"
            rowsPerPageOptions={[50, 100, 200]}
            count={40}
            rowsPerPage={50}
            page={0}
          />
        </div>
        <div className="rounding_filters">
          <div className="rounding_filter_dropdown">
            <Dropdown label="Provider" />
          </div>
          <div className="rounding_filter_dropdown">
            <Dropdown label="Facility" />
          </div>
          <div className="rounding_filter_dropdown">
            <Dropdown
              label="Status"
              value={status}
              options={[
                { name: "Active", value: 1 },
                { name: "Discharged", value: 2 },
              ]}
            />
          </div>
          <div className="rounding_filter_button">
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
            >
              Filter
            </Button>
          </div>
          <div className="rounding_filter_button">
            <Button variant="outlined" color="primary">
              Download
            </Button>
          </div>
        </div>
      </div> */}

      <div className="rounding_main_panel_container">
        <div className="rounding_table_main_container">
          <TableContainer elevation={0} className="rounding_table_container">
            <Table size="small" stickyHeader>
              <TableHead className="rounding_table_head">
                <TableRow>
                  <TableCell className="rounding_table_head_cell">
                    <Checkbox color="primary" size="small" />
                  </TableCell>
                  {headCells.map((x) => (
                    <TableCell className="rounding_table_head_cell" key={x.id}>
                      {x.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length ? (
                  rows.map((x, i) => (
                    <TableRow
                      key={i}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowSelect(x.roundingId)}
                      className={
                        x.roundingId === selectedRounding
                          ? "rounding_selected_row"
                          : "rounding_row"
                      }
                    >
                      <TableCell className="rounding_table_body_cell">
                        <Checkbox color="primary" size="small" />
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.patientMrn}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell patient">
                        {x.patientFirstName + " " + x.patientLastName}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.patientDob}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.locationName}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.patientBed}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.los}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {x.isDischarged && <img src={dischargeIcon} />}
                      </TableCell>
                      <TableCell className="rounding_table_body_cell">
                        {formatDate(x.startDate)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
              <div className="add_new_icon_container">
                <div className="add_new_icon" onClick={handleAddIcon}>
                  <AddRoundedIcon style={{ color: "#fff", fontSize: "2rem" }} />
                </div>
              </div>
            </Table>
          </TableContainer>
        </div>
        <div className="rounding_second_panel">
          <div className="rounding_second_panel_card">
            <div className="rounding_second_panel_card_first_item">
              <div className="rounding_second_panel_card_item_row">
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="rounding_second_panel_card_heading">
                    {details.patientFirstName + " " + details.patientLastName}
                  </div>
                  <div className="rounding_action_link">Edit</div>
                </div>
                <div style={{ fontWeight: "500" }}>{details.locationName}</div>
              </div>
              <div className="rounding_second_panel_card_item_row">
                <div style={{ fontSize: "14px", color: "#828282" }}>
                  {details.patientDob}
                </div>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#828282",
                  }}
                >
                  DOS: {details.startDate ? formatDate(details.startDate) : ""}
                </div>
              </div>
            </div>

            <div
              className="icd_container"
              style={{ paddingTop: "0.8rem", paddingBottom: "0.5rem" }}
            >
              <div className="rounding_second_panel_card_item">
                <div
                  style={{ paddingBottom: "4px" }}
                  className="rounding_second_panel_card_heading"
                >
                  ICD
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="rounding_action_link">Search</div>
                  <div className="rounding_action_link">Favorites</div>
                  <div>
                    <MenuRoundedIcon
                      fontSize="small"
                      style={{ color: "#828282", paddingTop: "3px" }}
                    />
                  </div>
                </div>
              </div>

              <div className="icd_search_items_container">
                <div className="icd_search_item">
                  <Autocomplete
                    fullWidth
                    options={[]}
                    // onChange={(e, x) => handleIcd("dx1", x)}
                    value={icdList.dx1.icdCode}
                    // getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ICD1"
                        // onChange={icdSearchFn}
                      />
                    )}
                  />
                  {/* <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={"dx_addicon"}
                    onClick={() => setopenIcd({ status: true, key: "DX1" })}
                  /> */}
                  <StarIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                  />
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    // onClick={() => setDx1(null)}
                  />
                </div>

                <div className="icd_search_item">
                  <Autocomplete
                    fullWidth
                    options={[]}
                    // onChange={(e, x) => handleIcd("dx1", x)}
                    value={icdList.dx2.icdCode}
                    // getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ICD2"
                        // onChange={icdSearchFn}
                      />
                    )}
                  />
                  {/* <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={"dx_addicon"}
                    onClick={() => setopenIcd({ status: true, key: "DX1" })}
                  /> */}
                  <StarIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                  />
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    // onClick={() => setDx1(null)}
                  />
                </div>

                <div className="icd_search_item">
                  <Autocomplete
                    fullWidth
                    options={[]}
                    // onChange={(e, x) => handleIcd("dx1", x)}
                    value={icdList.dx3.icdCode}
                    // getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ICD3"
                        // onChange={icdSearchFn}
                      />
                    )}
                  />
                  {/* <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={"dx_addicon"}
                    onClick={() => setopenIcd({ status: true, key: "DX1" })}
                  /> */}
                  <StarIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                  />
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    // onClick={() => setDx1(null)}
                  />
                </div>

                <div className="icd_search_item">
                  <Autocomplete
                    fullWidth
                    options={[]}
                    // onChange={(e, x) => handleIcd("dx1", x)}
                    value={icdList.dx4.icdCode}
                    // getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ICD4"
                        // onChange={icdSearchFn}
                      />
                    )}
                  />
                  {/* <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={"dx_addicon"}
                    onClick={() => setopenIcd({ status: true, key: "DX1" })}
                  /> */}
                  <StarIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                  />
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className="icd_search_icon"
                    // onClick={() => setDx1(null)}
                  />
                </div>
              </div>
            </div>

            <div className="cpt_container">
              <div className="rounding_second_panel_card_item">
                <div style={{ display: "flex", gap: "50px" }}>
                  <div
                    style={{ paddingBottom: "4px" }}
                    className="rounding_second_panel_card_heading"
                  >
                    CPT
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      className={ip ? "selected_ip_op" : "cpt_ip_op"}
                      onClick={() => setIp(true)}
                    >
                      I/P
                    </div>
                    <div
                      className={ip ? "cpt_ip_op" : "selected_ip_op"}
                      onClick={() => setIp(false)}
                    >
                      O/P
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="rounding_action_link">Favorites</div>
                  <div>
                    <MenuRoundedIcon
                      fontSize="small"
                      style={{
                        color: "#828282",
                        paddingTop: "3px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ paddingBottom: "0.5rem" }}>
                <div style={{ paddingBottom: "0.8rem" }}>
                  <Tabs
                    value={cptTab}
                    indicatorColor="primary"
                    textColor="primary"
                    className="cpt_tabs_header"
                    onChange={handleCptTab}
                  >
                    <Tab label="New" {...a11yProps(0)}></Tab>
                    <Tab label="Sub" {...a11yProps(1)}></Tab>
                    <Tab label="Consult" {...a11yProps(2)}></Tab>
                    <Tab label="ICU" {...a11yProps(3)}></Tab>
                    <Tab label="Dis" {...a11yProps(4)}></Tab>
                  </Tabs>
                  <TabPanel value={cptTab} index={0}>
                    <>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          paddingBottom: "1rem",
                          paddingTop: "1rem",
                        }}
                      >
                        {cptList &&
                          cptList.map((x) => (
                            <div>
                              <Chip
                                className="cpt_chip"
                                label={x.cptCode}
                                variant="outlined"
                                color="primary"
                                size="small"
                              />
                            </div>
                          ))}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          paddingBottom: "1rem",
                          flexWrap: "wrap",
                        }}
                      >
                        {cptList &&
                          cptList.map((x) => (
                            <div className="cpt_card_container">
                              <div className="cpt_card_row1">
                                <div className="cpt_code">{x.cptCode}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="cpt_modifier">U</div>
                                  <div className="cpt_modifier">M1</div>
                                  <div className="cpt_modifier">M2</div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    gap: "2px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ cursor: "pointer" }}>
                                    <CloseIcon fontSize="small" />
                                  </div>
                                  <div style={{ cursor: "pointer" }}>
                                    <StarIcon
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </div>
                                  <div style={{ cursor: "pointer" }}>
                                    <MenuRoundedIcon fontSize="small" />
                                  </div>
                                </div>
                              </div>
                              <div className="cpt_card_row2">
                                <div className="cpt_detail">
                                  {x.description}
                                </div>
                                <div className="cpt_detail">
                                  Allowed: ${x.amount}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  </TabPanel>
                  <TabPanel value={cptTab} index={1}>
                    Sub
                  </TabPanel>
                  <TabPanel value={cptTab} index={2}>
                    Consult
                  </TabPanel>
                  <TabPanel value={cptTab} index={3}>
                    ICU
                  </TabPanel>
                  <TabPanel value={cptTab} index={4}>
                    Dis
                  </TabPanel>
                </div>
              </div>
            </div>

            <div className="note_container">
              <div className="rounding_second_panel_card_item_note">
                <div className="rounding_second_panel_card_heading">
                  Notes to biller
                </div>
                <div className="rounding_action_link">View</div>
              </div>
              <div className="notes_input_container">
                <div>
                  <TextField
                    size="small"
                    multiline
                    variant="outlined"
                    placeholder="Enter any notes for biller"
                    value={billerNote}
                    onChange={(e) => setBillerNote(e.target.value)}
                    className="note_text_field"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    maxHeight: "32px",
                  }}
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    style={{ color: "#ffff" }}
                  >
                    Submit
                  </Button>
                  <Button size="small" color="primary" variant="outlined">
                    Clear
                  </Button>
                </div>
              </div>
            </div>

            <div className="note_container">
              <div className="rounding_second_panel_card_item_note">
                <div className="rounding_second_panel_card_heading">
                  Rounding notes
                </div>
                <div className="rounding_action_link">View</div>
              </div>
              <div className="notes_input_container">
                <div>
                  <TextField
                    size="small"
                    multiline
                    variant="outlined"
                    placeholder="Enter rounding notes"
                    value={roundingNote}
                    onChange={(e) => setRoundingNote(e.target.value)}
                    className="note_text_field"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "flex-end",
                    maxHeight: "32px",
                  }}
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    style={{ color: "#ffff" }}
                  >
                    Submit
                  </Button>
                  <Button size="small" color="primary" variant="outlined">
                    Clear
                  </Button>
                </div>
              </div>
            </div>

            <div className="actions_container">
              <div className="rounding_second_panel_card_item">
                <div className="rounding_second_panel_card_heading">
                  Actions
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <Button
                  size="small"
                  style={{ color: "#828282", borderColor: "#828282" }}
                  variant="outlined"
                  onClick={() => setOpenEdit(true)}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  style={{ color: "#E39600", borderColor: "#E39600" }}
                  variant="outlined"
                  onClick={() => setOpenDischarge(true)}
                >
                  Discharge
                </Button>
                <Button
                  size="small"
                  style={{ color: "#4F566B", borderColor: "#4F566B" }}
                  variant="outlined"
                  onClick={() => setOpenRemove(true)}
                >
                  Delete
                </Button>
                <Button
                  size="small"
                  color="primary"
                  style={{ color: "#ffff" }}
                  variant="contained"
                  onClick={() => handleSaveRoundingDetails()}
                >
                  Save changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  roundingData: state.rounding,
});

const mapDispatchToProps = (dispatch) => ({
  getRoundingList: (values) => dispatch(getRoundingList(values)),
  addRounding: (values) => dispatch(addRounding(values)),
  editRounding: (values) => dispatch(editRounding(values)),
  getRoundingDetails: (values) => dispatch(getRoundingDetails(values)),
  saveRoundingDetails: (values) => dispatch(saveRoundingDetails(values)),
  searchPatient: (values) => dispatch(searchPatient(values)),
  savePatient: (values) => dispatch(savePatient(values)),
  deletePatient: (values) => dispatch(deletePatient(values)),
  dischargePatient: (values) => dispatch(dischargePatient(values)),
});

Rounding.propTypes = {
  roundingData: PropTypes.object,
  getRoundingList: PropTypes.func,
  addRounding: PropTypes.func,
  editRounding: PropTypes.func,
  getRoundingDetails: PropTypes.func,
  saveRoundingDetails: PropTypes.func,
  searchPatient: PropTypes.func,
  savePatient: PropTypes.func,
  deletePatient: PropTypes.func,
  dischargePatient: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rounding);
