import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
// import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import environment from "../../environments/environment";
import {
  GET_ROUNDING_LIST,
  GET_ROUNDING_CACHE,
  ADD_ROUNDING,
  GET_ROUNDING_DETAILS,
  SAVE_ROUNDING_DETAILS,
  EDIT_ROUNDING,
  SEARCH_PATIENT,
  SAVE_PATIENT,
  DELETE_PATIENT,
  DISCHARGE_PATIENT,
  REQUEST_CANCEL,
} from "./rounding.types";
import {
  getRoundingListRes,
  getRoundingCacheRes,
  addRoundingRes,
  getRoundingDetailsRes,
  saveRoundingDetailsRes,
  editRoundingRes,
  searchPatientResponse,
  savePatientResponse,
  deletePatientResponse,
  dischargePatientResponse,
} from "./rounding.actions";
// import { Apis } from 'Redux/APIs'
const { clinicBaseUrl } = environment;
const RoundingEpic = {};

RoundingEpic.getRoundingList = (action$) =>
  action$.pipe(
    ofType(GET_ROUNDING_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/list/all?providerIds=${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getRoundingListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.getRoundingCache = (action$) =>
  action$.pipe(
    ofType(GET_ROUNDING_CACHE),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/user/logged-in/info/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getRoundingCacheRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.addRounding = (action$) =>
  action$.pipe(
    ofType(ADD_ROUNDING),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-type": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => addRoundingRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.getRoundingDetails = (action$) =>
  action$.pipe(
    ofType(GET_ROUNDING_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getRoundingDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.saveRoundingDetails = (action$) =>
  action$.pipe(
    ofType(SAVE_ROUNDING_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-type": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/visit/charge/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveRoundingDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.editRounding = (action$) =>
  action$.pipe(
    ofType(EDIT_ROUNDING),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-type": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/${action.payload.roundingId}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => editRoundingRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.searchPatient = (action$) =>
  action$.pipe(
    ofType(SEARCH_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-clinic-service/v1/patient/search?firstName=${action.payload.firstName}&lastName=${action.payload.lastName}&dob=${action.payload.dob}&clinicId=${action.payload.clinicId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => searchPatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.savePatient = (action$) =>
  action$.pipe(
    ofType(SAVE_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-clinic-service/v1/patient/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.deletePatient = (action$) =>
  action$.pipe(
    ofType(DELETE_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/${action.payload}`,
        method: "DELETE",
      }).pipe(
        map(
          (response) => deletePatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.dischargePatient = (action$) =>
  action$.pipe(
    ofType(DISCHARGE_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/dischargePatient/${action.payload.roundingId}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => dischargePatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default RoundingEpic;
