import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { logout } from "Redux/Login/login.actions";
import environment from "environments/environment";
import { useHistory } from "react-router-dom";
import "./style.css";

function Header(props) {
  const history = useHistory();
  const [newMenuName, setNewMenuName] = useState("images");
  const [openedPopover, setOpenedPopover] = useState(false);
  const [clinicName, setClinicName] = useState("");
  const { systemName } = environment;
  const popoverAnchor = useRef(null);

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true);
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false);
  };

  const getAbrrev = (name) => {
    let nameArray = name.split(" ");
    if (nameArray.length > 1) {
      let n = nameArray.map((x) => x.charAt(0));
      return n.join("").substring(0, 2);
    } else {
      return nameArray[0].charAt(0);
    }
  };

  const redirectToMenuItems = (menuName) => {
    if (menuName === "rounding") {
      history.push("/rounding");
    }
    if (menuName === "images") {
      history.push("/images");
    }
  };

  useEffect(() => {
    let value = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    setNewMenuName(value);
  }, [newMenuName]);

  useEffect(() => {
    let c = localStorage.getItem("clinicName");
    if (c) {
      console.log(c);
      setClinicName(c);
    } else {
      setClinicName("Clinic");
    }
  }, [localStorage.getItem("clinicName")]);

  const handleLogout = () => {
    props.logout(localStorage.getItem("refreshToken"));
  };

  useEffect(() => {
    if (
      props.loginData &&
      props.loginData.logoutResponse &&
      props.loginData.logoutResponse.response
    ) {
      if (props.loginData.logoutResponse.response.responseCode === 0) {
        window.location.href = "/login";
      }
    }
  }, [props.loginData.logoutResponse.response]);

  return (
    <div className="dashboard_main_container">
      <div className="dashboard_header_left">
        <ul>
          {systemName === "System dev" && (
            <li
              className={newMenuName === "rounding" ? "selected" : null}
              onClick={() => {
                redirectToMenuItems("rounding");
                setNewMenuName("");
              }}
            >
              Rounding List
            </li>
          )}
          <li
            className={newMenuName === "images" ? "selected" : null}
            onClick={() => {
              redirectToMenuItems("images");
              setNewMenuName("");
            }}
          >
            Images
          </li>
        </ul>
      </div>
      <div
        className="dashboard_header_right"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        onClick={() => setOpenedPopover(true)}
      >
        <div className="clinic_profile_circle" ref={popoverAnchor}>
          {clinicName && getAbrrev(clinicName)}
        </div>
        <KeyboardArrowDownIcon color="primary" />
        <Popover
          id="mouse-over-popover"
          className="dashboard_header_popover"
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          }}
        >
          <div className="clinicHeader__popover">
            <div className="clinicHeader__popover--name">{clinicName}</div>
            <div className="clinicHeader__popover--list">
              <div>Settings</div>
              <div className="logout" onClick={handleLogout}>
                Logout
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (values) => dispatch(logout(values)),
});

Header.propTypes = {
  loginData: PropTypes.object,
  logout: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
