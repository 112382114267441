import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import environment from "../../environments/environment";
import { LOGIN, LOGOUT, REQUEST_CANCEL } from "./login.types";
import { loginResponse, logoutResponse } from "./login.actions";
// import { Apis } from 'Redux/APIs'
const { clinicBaseUrl } = environment;
const LoginEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Merina Joy <merina@tensaw.email>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */

LoginEpic.login = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          "application-id": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/user/authentication/login`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

LoginEpic.logout = (action$) =>
  action$.pipe(
    ofType(LOGOUT),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-id": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/user/authentication/logout?refreshToken=${action.payload}`,
        method: "POST",
      }).pipe(
        map(
          (response) => logoutResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default LoginEpic;
